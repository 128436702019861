import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'


Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


Vue.config.productionTip = false

Vue.use(ElementUI);

Vue.prototype.$axios = axios
/* eslint-disable no-new */
console.log(111)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
