<template>
  <div class="common">
      <div class="header">
          <h1>{{extend2}}</h1>
          <p>{{extend1}},请保持校门口道路通畅! </p>
          <span>{{date|formateTime}}</span>
          <div class="pageszie">第 {{pagesize}} 页</div>
      </div>
     <div class="boxs">
          <el-carousel class="swiperbox" height="73vh" ref="box" :interval="10000" :loop='true' direction="vertical" indicator-position="none" :autoplay="true">
        <el-carousel-item v-for="(item,index) in listdata" :key="index" >
        <div class="continer">
            <div class="continer_box">
                <div  :class="items.State == -1 && items.Status ==0 ? 'classlist classlist2' : items.State == 0 && items.Status == 0 ? 'classlist classlist0' : items.State == 1 && items.Status == 0 ? 'classlist classlist1' : items.State == -1 && items.Status == 1 ? 'classlist2 classlistshet' :  items.State == 0 && items.Status == 1 ? 'classlist0 classlistshet' : 'classlist1 classlistshet'" v-for="(items,index1) in listdata[index]" :key="index1">
                        <div :class="items.Name.length > 6 ? 'classinfos' : 'classinfo' ">{{items.Name}}{{items.Status == 0 ? '班' : ''}}</div>
                        <div class="classstate">{{items.State ==0 ? '未放学' : items.State ==1 ? '放学中' : items.LastTime +' 已放学'}}</div>
                    </div>
                </div>
            
           </div>
        </el-carousel-item>
     
    </el-carousel>
     </div>

  </div>
</template>

<script>
//格式化后的时间格式：年-月-日 时：分：秒的
	var	parseDate=function(datetime){
		return datetime<10?'0'+datetime:datetime;	
	}

export default {
    data(){
        return{
            hH:'',
            listdata:'',
            date: new Date(),
            pagesize:1,
            extend1:'',
            extend2:'',
            schoolId:''
        }
    },
    filters:{
        formateTime:function(val){
            var date=new Date(val);
            var year=date.getFullYear();
            var month=parseDate(date.getMonth()+1);
            var day=parseDate(date.getDate());
            var hours=parseDate(date.getHours());
            var min=parseDate(date.getMinutes());
            var sec=parseDate(date.getSeconds());
            return year+'-'+month+'-'+day+' '+hours+":"+min+":"+sec;
        }
    },
    mounted(){
        var that=this;//作用域一致
			this.timer=setInterval(function(){
				that.date=new Date();
			},1000);
            setInterval(()=>{
                setInterval(()=>{
                    if(that.$refs.box.activeIndex == 1){
                        that.$refs.box.activeIndex == 0
                    }
                    that.pagesize = that.$refs.box.activeIndex + 1
                },500)
                
         },10000)
    },
    created(){
        this.schoolId = this.getQueryString('schoolid')
        if(this.schoolId == null){
            this.schoolId = 1
        }
        this.$axios({
            url: "https://www.chatroom.codetu.vip/schoolapi/classroom/getclassrooms?pageSize=20&pageSize2=16&schoolid="+this.schoolId,
            method: "get",
            params: {}
            }).then(res => {
                this.listdata = JSON.parse(res.data.message)
                this.extend1 = res.data.extend1
                this.extend2 = res.data.extend2
                this.hendlsetintor()
            })
    },
    beforeDestroy() {
        if (this.timer) {
        clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    methods:{
        hendlsetintor(){
            let self = this
            setInterval(()=>{
                self.$axios({
                url: "https://www.chatroom.codetu.vip/schoolapi/classroom/getclassrooms?pageSize=20&pageSize2=16&schoolid="+this.schoolId,
                method: "get",
                params: {}
                }).then(res => {
                    if(res.data.message != ''){
                        self.listdata = JSON.parse(res.data.message)
                        self.extend1 = res.data.extend1
                        self.extend2 = res.data.extend2
                    }
                }).catch(error=>{
                    console.log(error)
                })
           },5000)
        },
        getQueryString (name) {
            const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
            const r = window.location.hash.substr(3).match(reg);
            if (r !== null) return decodeURIComponent(r[2]);
            return null;
        }
    }
}
</script>

<style>
.common{
    padding: 1vw;
}
.header{
    height: 22vh;
    background: #a2d7ff;
    border-radius: 20px;
    position: relative;
}
.header h1{
    font-size: 3rem;
    color: #333;
    padding-top: 1.5rem;
    font-weight: bold;
}
.header p{
    font-size: 2.2rem;
    color: #e12727;
    padding-top: .5rem;
    display: inline-block;
    position: relative;
    width: 100%;
}
.header span{
    font-size: 2rem;
    color: #333;
    position: absolute;
    right: 5%;
    top:2.5rem;
}
.continer{
    margin-top: 20px;
}
.continer_box{
   overflow: hidden;
     margin-bottom: 20px;
}
.continer_box .classlist:nth-child(5){
    margin-right: 0;
}
.continer_box .classlist:nth-child(10){
    margin-right: 0;
}
.continer_box .classlist:nth-child(15){
    margin-right: 0;
}
.continer_box .classlist:nth-child(20){
    margin-right: 0;
}
.continer_box .classlistshet:nth-child(4){
    margin-right: 0;
}
.continer_box .classlistshet:nth-child(8){
    margin-right: 0;
}
.continer_box .classlistshet:nth-child(12){
    margin-right: 0;
}
.continer_box .classlistshet:nth-child(16){
    margin-right: 0;
}
.classlist{
   width: 19.5%;
   height: 16.5vh;
   float: left;
   margin-bottom: 1.3vh;
   margin-right: .58%;
   background: #316ec8;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.classlist0 {
    display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.classlistshet{
   width: 24.5% ;
   height: 16.5vh;
   float: left;
   margin-bottom: 1.3vh;
   margin-right: .58%;
   background: #316ec8;
   border-radius: 20px;
}
.classlist1{
    background: #d49405;
}
.classlist2{
    background: #9d3437;
}

.classlistshet .classinfo{
    color: #fff;
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    white-space:nowrap;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    overflow: hidden;
}
.classlist .classinfos{
    color: #fff;
    font-size: 36px;
    width:80%;
    margin:10px auto;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
}
.classlistshet .classinfos{
    color: #fff;
    font-size: 36px;
    width:80%;
    margin:10px auto;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
}
.classlist .classstate{
    color: #fff;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}
.classlistshet .classstate{
    color: #fff;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}
.classlistshet .classinfo{
    color: #fff;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    white-space:nowrap;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    overflow: hidden;
}
.boxs{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.swbox{
    width: 100%;
    height: 86vh;
}
.pageszie{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 218px;
    height: 74px;
    line-height: 74px;
    text-align:center;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    background: #316ec8;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
</style>

